<template>
    <div class="module">
        <van-nav-bar title="通风模块" left-arrow  @click-left="$router.go(-1)" />
        <van-steps direction="vertical" :active="15" active-color="#1677ff" class="setps-content">
            <van-step v-for="(item,index) in devList" :key="index">
                <div class="title">
                    {{item.name}}
                </div>
                <div v-if="item.type === 1">
                    <div class="marbtm5">CO检测值：{{item.co}} ppm</div>
                    <div class="marbtm5">VI检测值：{{item.vi}} ppm</div>
                    <div class="marbtm5">故障报警：
                        <van-tag size="medium" type="primary" v-if="item.status">正常</van-tag>
                        <van-tag size="medium" type="warning" v-else>故障</van-tag>
                    </div>
                </div>
                <div v-else-if="item.type ===2">
                    <div class="marbtm5">风速值：{{item.speed}} m/s</div>
                    <div class="marbtm5">风向：{{item.dir}} </div>
                    <div class="marbtm5">故障报警：
                        <van-tag size="medium" type="primary" v-if="item.status">正常</van-tag>
                        <van-tag size="medium" type="warning" v-else>故障</van-tag>
                    </div>
                </div>
                <div v-else-if="item.type ===3">
                    <div class="marbtm5">正转状态：{{item.status0 ? '正常' : '故障' }}</div>
                    <div class="marbtm5">反转状态：{{item.status1 ? '正常' : '故障' }}</div>
                    <div class="marbtm5">启停状态：{{item.status2 ? '正常' : '故障' }}</div>
                    <div class="marbtm5">故障反馈：{{item.status3 ? '正常' : '暂无' }}</div>
                    <div class="marbtm5">总体运行故障报警：
                        <van-tag size="medium" type="primary" v-if="item.status4">正常</van-tag>
                        <van-tag size="medium" type="warning" v-else>故障</van-tag>
                    </div>
                </div>
                <div>更新时间：{{item.time}}</div>
            </van-step>
        </van-steps>
    </div>
</template>

<script>
export default {
    data(){
        return{
            devList:[
                {name:'CO/VI/NO2检测器-出京1',type:1,status:1,co:'6.60',vi:'1839.12',time:'2021-11-01 12:30'},
                {name:'CO/VI/NO2检测器-出京2',type:1,status:1,co:'30.00',vi:'2424.20',time:'2021-11-01 08:30'},
                {name:'CO/VI/NO2检测器-进京1',type:1,status:1,co:'1.42',vi:'1720.43',time:'2021-11-01 12:30'},
                {name:'CO/VI/NO2检测器-进京2',type:1,status:1,co:'1.20',vi:'1532.65',time:'2021-11-01 02:20'},
                {name:'风速风向检测-出京',type:2,status:1,speed:'3.2',dir:'东南',time:'2021-11-01 11:30'},
                {name:'风速风向检测-进京',type:2,status:1,speed:'2.1',dir:'北',time:'2021-11-01 09:30'},
                {name:'射流风机-出京1组',type:3,status0:1,status1:1,status2:1,status3:0,status4:1,temp:'32.2',lum:'100',time:'2021-11-01 12:30'},
                {name:'射流风机-出京2组',type:3,status0:1,status1:1,status2:1,status3:0,status4:1,temp:'30.4',lum:'100',time:'2021-11-02 12:00'},
                {name:'射流风机-出京3组',type:3,status0:1,status1:1,status2:1,status3:0,status4:1,lum:'1667.5',time:'2021-11-02 12:10'},
                {name:'射流风机-出京4组',type:3,status0:1,status1:1,status2:1,status3:0,status4:1,lum:'0.0',time:'2021-11-02 14:30'},
                {name:'射流风机-出京5组',type:3,status0:1,status1:1,status2:1,status3:0,status4:1,temp:'28.9',lum:'100',time:'2021-11-02 14:50'},
                {name:'射流风机-出京1组',type:3,status0:1,status1:1,status2:1,status3:0,status4:0,temp:'32.2',lum:'100',time:'2021-11-02 15:30'},
                {name:'射流风机-出京2组',type:3,status0:1,status1:1,status2:1,status3:0,status4:1,temp:'32.5',lum:'80',time:'2021-11-02 16:00'},
                {name:'射流风机-出京3组',type:3,status0:1,status1:1,status2:1,status3:0,status4:1,temp:'35.0',lum:'100',time:'2021-11-02 18:10'},
                {name:'射流风机-出京4组',type:3,status0:1,status1:1,status2:1,status3:0,status4:1,temp:'33.0',lum:'100',time:'2021-11-02 19:00'},
                {name:'射流风机-出京5组',type:3,status0:1,status1:1,status2:1,status3:0,status4:1,temp:'30.2',lum:'100',time:'2021-11-02 19:10'},
            ]
        }
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/tunnel/module.scss';
</style>